@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:hwb(254 9% 65%);
  --allowed:#BE7009;
  --notallowed:rgb(171, 171, 171);
  --darkColor:#1c0f44;
  --bg-main-content:#ffffff;
}
div,span{
  font-family: "Tajawal", sans-serif;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--themColor)!important;
}
.app{
  background-color: var(--themColor)!important;
}
.bg-main-content{
  background: var(--bg-main-content);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-loading ::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 70%;
  background-color: var(--darkColor);
  opacity: 60%;
  top:10px;
}
.app-active ::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 70%;
  background-color: var(--secondaryColor);
  opacity: 30%;
  top:10px;
}

.active{
  color:var(--secondaryColor);
}

.active-hover :hover{
  color:var(--secondaryColor) !important;
}

::-webkit-scrollbar{
  display: none!important;
}
    .tooltip{
      padding:5px 0px;
      width: 100%!important;
    }
 .tooltip::after {
  content: attr(data-title);
  position: absolute!important;
  background:var(--allowed);
  color:var(--background-color);
  padding: 14px 10px;
  border-radius: 5px 0px 0px 5px;
  font-size: 14px;
  /* top: 0px; */
  white-space: nowrap;
  right:100% !important;;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.9s ease-in-out;
  z-index: 22;
  height: 100%!important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--background-color);
}

.tooltip:dir(rtl)::after {
  right: 100% !important;
  border-radius: 5px 0px 0px 5px;
}

.tooltip:dir(ltr)::after {
  width: max-content;
  left: 100% !important;
  border-radius: 0px 5px 5px 0px;
} 
.tooltip:dir(ltr) span {
  left:0px;
} 
.not-allow span{
  background-color: var(--notallowed)!important;
  /* display: none; */
  opacity:0.5;
}
 .not-allow::after {
  /* display: none; */
  opacity:0.5;
  background-color:var(--notallowed);
}
.tooltip  span{
  width: 0%;
  height: 100%;
  background-color:var(--allowed);
  position: absolute!important;
  z-index: 4;
  transition: 0.4s;
  border-bottom: 1px solid white;
  border-radius:1px;
  right: 0px;
}
.tooltip:hover span{
  width: 100%!important;
}
   .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }

.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}
/* .primary-btn :hover{
  border-width: 1px ;
  border-color: var(--darkColor);
  color: var(--darkColor);
  font-weight: bold !important;
} */
